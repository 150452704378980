import React, {useEffect, useState} from 'react';
import './ClassExamEditAnswer.css';

const ClassExamEditAnswer = ({show, setShow, answers, setAnswers, currentIndex, setcurrentIndex }) => {
    const [input, setInput] = useState('');

    useEffect(() => {
        console.log(answers[Object.keys(answers)[currentIndex]])
        setInput(answers[Object.keys(answers)[currentIndex]]);
    }, [currentIndex]);

    useEffect(() => {
        // let html = convertToHTML(editorState.getCurrentContent())
        // setConvertedContent(html)
        // console.log(html);
        // currentAnswer.setCurrentAnswerValue(html);
        // setInput(html);
    }, []);

    return (
        <div className={`ClassExamEditAnswer ${show && 'ClassExamEditAnswer__show'}`}>
            <div className="ClassExamEditAnswer__container">
                <label htmlFor="">اضافة اجابة</label>
                <textarea value={input} onChange={e => {
                    setAnswers(v => {
                        console.log(e.target.value, );
                        setInput(e.target.value);
                        const changedAnswer = Object.keys(answers)[currentIndex - 1];
                        let stateCopy = v;
                        stateCopy[changedAnswer] = e.target.value;
                        console.log(stateCopy);
                        return stateCopy;
                    });
                } } />
                <button onClick={e => {
                    setShow(false);
                    setInput('');
                }}>اضافة</button>
            </div>
        </div>
    );
};

export default ClassExamEditAnswer;