import React from 'react';
import './Header.css';
import {useNavigate} from "react-router-dom";

function Header({text}) {
  const navigate = useNavigate();
  return (
    <div className='Header'>{text}<i onClick={e => navigate(-1)} className="fa-solid fa-arrow-left"></i></div>
  )
}

export default Header;